import "./App.css";

function App() {
  return (
    <div className="App">
      <p>😌</p>
      <p>Living the moment!</p>
    </div>
  );
}
export default App;
